<template>
  <app-list-view
    server-side
    app="lawsuit"
    model="lawsuitcategory"
    api-url="lawsuit/lawsuit-category/"
    :title="$t('menu.lawsuitCategory')"
    :createTo="{ name: 'lawsuitCategoryCreate' }"
    :editTo="{ name: 'lawsuitCategoryEdit' }"
    :headers="headers"
  />
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'lawsuitCategoryList',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        { text: this.$t('fields.lawsuitCategoryName'), value: 'name' },
        { text: this.$t('fields.state'), value: 'state' },
        {
          text: this.$t('fields.lawsuitType'),
          value: 'businessCategory',
          link: {
            prepareRouter: this.lawsuitTypeLink
          },
          hideFilter: true
        },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'lawsuitCategory'
    })
  },
  methods: {
    lawsuitTypeLink(item) {
      return {
        name: 'lawsuitType',
        query: { lawsuit_categories: item.id }
      }
    }
  }
}
</script>
